import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  // Tracing
  tracesSampleRate: process.env.GATSBY_SENTRY_TRACES_SAMPLE_RATE || 0.8,
  replaysSessionSampleRate: process.env.GATSBY_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.1,
  replaysOnErrorSampleRate: process.env.GATSBY_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0,
});
